<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">Urls</h3>
    </div>
    <div class="card-body pt-3 pb-0">
      <div
        v-if="!list.length"
        class="card-label font-weight-bolder text-dark text-center mb-3"
      >
        No data
      </div>
      <!--begin::Table-->
      <div v-if="list.length > 0" class="table-responsive">
        <table
          class="table table-borderless table-vertical-center urls-list-wrp"
        >
          <thead>
            <tr>
              <th class="p-0" style="max-width: 300px">URL</th>
              <th class="p-0" style="min-width: 50px"></th>
              <th :class="`p-0`">
                <span class="d-flex align-items-center">Total visits </span>
              </th>
              <th class="p-0">
                <span class="d-flex align-items-center">Created at </span>
              </th>
              <th class="p-0">
                <span class="d-flex align-items-center">Last visit at </span>
              </th>
              <th class="p-0" style="min-width: 100px">IP</th>
              <th class="p-0" style="min-width: 100px">Country</th>
            </tr>
          </thead>
          <tbody v-if="list">
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <td class="pl-0" style="max-width: 300px">
                  <div class="text-truncate">
                    <b>{{ item.uri }}</b>
                  </div>
                </td>
                <td class="pr-0">
                  <button
                    class="btn btn-icon btn-light btn-sm"
                    @click="onCopyToClipboard(item.uri)"
                    v-b-tooltip.hover.left="'Copy url'"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <path
                            d="M10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L10.1818182,16 C8.76751186,16 8,15.2324881 8,13.8181818 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </span>
                  </button>
                </td>
                <td class="pl-0">
                  {{ item.total_visits }}
                </td>
                <td class="pl-0">
                  {{ renderDate(item.created_at) }}
                </td>
                <td class="pl-0">
                  {{ renderDate(item.last_visit_at) }}
                </td>
                <td class="pl-0">
                  {{ item.first_ip }}
                </td>
                <td class="pl-0">
                  {{ item.country_code }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as TYPES from "@/core/services/store/modules/domains/types";
import { renderDate } from "@/core/services/services";

export default {
  name: "UrlsList",
  computed: {
    ...mapState({
      list: (state) => state.domains.urls,
    }),
    renderDate: () => renderDate,
  },
  methods: {
    ...mapActions({
      loadUrls: `domains/${TYPES.GET_DOMAIN_URLS}`,
    }),
    onCopyToClipboard: function (value) {
      let body = window.parent.document.querySelector("body");
      let input = window.parent.document.createElement("input");
      body.appendChild(input);
      input.value = value;
      input.select();
      document.execCommand("copy");
      body.removeChild(input);
      this.$toaster.success("Copy success");
      return true;
    },
  },
  created: function () {
    this.loadUrls(this.$route.params.id);
  },
};
</script>

<style scoped lang="scss">
.urls-list-wrp {
  td {
    min-width: 110px;
  }
}
</style>
