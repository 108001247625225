<template>
  <div>
    <div class="row">
      <div class="col-xxl-12">
        <DomainDetails></DomainDetails>
        <UrlsList />
      </div>
    </div>
  </div>
</template>

<script>
import DomainDetails from "./components/DomainDetails";
import UrlsList from "./components/UrlsList";

export default {
  name: "Domain",
  components: { DomainDetails, UrlsList },
};
</script>

<style scoped></style>
